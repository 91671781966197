import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/api_products'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      mamulkod: null,
      kalite: null,
      sen: null,
      sgr: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    filterData: {
      kalite: [],
      sen: [],
      sgr: [],
    },
    filterParams: {
      pcode: null,
      kalite: null,
      sen: null,
      sgr: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    filterData(state) {
      return state.filterData
    },
    filterParams(state) {
      return state.filterParams
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_FILTER_DATA(state, data) {
      state.filterData = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          mamulkod: null,
          kalite: null,
          sen: null,
          sgr: null,
        }
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getFilterData({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(`${ROOT_URL}/filterData`, data)
        .then(response => {
          commit('SET_FILTER_DATA', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, mamulkod) {
      const data = JSON.stringify({
        where: {
          'CRM_MAM_KOD.mamulkod': mamulkod,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
