import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/catalogs'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      title: null,
      content: null,
      notes: null,
      expiry_date: null,
      token: null,
      id_catalog_statuses: null,
      id_customers: null,
      items: [],
      itemsCount: 0,
      id_products: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    cloneResult: {
      message: null,
      status: null,
      id: null,
    },
    dataSaveLine: {
      message: null,
      status: null,
      id: null,
    },
    productFilterData: {
      keyword: null,
      width: null,
      weight: null,
      min_weight: null,
      max_weight: null,
      asinma: null,
      asinma_sonuc: null,
      pilling: null,
      pilling_sonuc: null,
      rh_kuru: null,
      rh_yas: null,
      hav_kaybi: null,
      id_product_qualities: null,
      id_product_groups: null,
      modalStatus: false,
    },
    catalogSearch: {
      keyword: null,
    },
    customerSend: {
      to: [],
      message: null,
      modalStatus: false,
      sending: false,
    },
    customerSendResult: {
      message: null,
      status: null,
    },
    testResultModal: {
      product: null,
      data: {
        id: null,
        asinma_min: null,
        asinma_max: null,
        asinma_sonuc: null,
        asinma_sonuc_text: null,
        hav_kaybi: null,
        pilling_min: null,
        pilling_max: null,
        pilling_sonuc: null,
        rh_kuru: null,
        rh_yas: null,
        id_products: null,
      },
      modalStatus: false,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    cloneResult(state) {
      return state.cloneResult
    },
    dataSaveLineStatus(state) {
      return state.dataSaveLine
    },
    productFilter(state) {
      return state.productFilterData
    },
    catalogSearch(state) {
      return state.catalogSearch
    },
    customerSend(state) {
      return state.customerSend
    },
    customerSendResult(state) {
      return state.customerSendResult
    },
    testResultModal(state) {
      return state.testResultModal
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_CLONE_RESULT(state, data) {
      state.cloneResult = data
    },
    SET_DATA_SAVE_LINE(state, data) {
      state.dataSaveLine = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        title: null,
        content: null,
        notes: null,
        expiry_date: null,
        token: null,
        id_catalog_statuses: null,
        id_customers: null,
        items: [],
        id_products: [],
      }
    },
    SET_SEND_RESULT(state, data) {
      state.customerSendResult = data
      state.customerSend.sending = false
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, params) {
      const data = JSON.stringify({
        where: {
          'catalogs.id': params.id,
        },
        array: false,
        detail: true,
        detailParams: {
          limit: params.limit,
          start: params.start,
        },
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    addLine({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/addLine`, data)
        .then(response => {
          commit('SET_DATA_SAVE_LINE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    reOrderingItem({ commit }, data) {
      return axiosIns
        .post(`${ROOT_URL}/reOrderingItem`, data)
        .then(response => {
          commit('SET_DATA_SAVE_LINE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeLine({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteLine`, { id })
        .then(response => {
          commit('SET_DATA_SAVE_LINE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    customerSend({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/customerSend`, params)
        .then(response => {
          commit('SET_SEND_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    cloneData({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/cloneData`, params)
        .then(response => {
          commit('SET_CLONE_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
