export default [
  {
    path: '/app/product-qualities',
    name: 'ProductQualities',
    component: () => import('@/views/Admin/Product_qualities/Index.vue'),
    meta: {
      pageTitle: 'Kalite Adı',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_QUALITY_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/product-qualities/add',
    name: 'ProductQualityAdd',
    component: () => import('@/views/Admin/Product_qualities/Add.vue'),
    meta: {
      pageTitle: 'Kalite Adı',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/product-qualities',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_QUALITY_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/product-qualities/edit/:id',
    name: 'ProductQualityEdit',
    component: () => import('@/views/Admin/Product_qualities/Edit.vue'),
    meta: {
      pageTitle: 'Kalite Adı',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/product-qualities',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_QUALITY_EDIT',
      action: 'read',
    },
  },
]
