import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/products'
export default {
  namespaced: true,
  state: {
    pending: 0,
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      primary_pcode: null,
      pcode: null,
      price: null,
      weight: null,
      width: null,
      content: null,
      details: null,
      id_users: null,
      id_product_groups: null,
      id_product_qualities: null,
      id_currencies: null,
      images: [],
      tests: {
        id: null,
        asinma: null,
        asinma_sonuc: null,
        pilling: null,
        pilling_sonuc: null,
        rh_kuru: null,
        rh_yas: null,
        hav_kaybi: null,
        id_products: null,
      },
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    filterData: {
      qualities: [],
      width: [],
      weight: [],
    },
    filterParams: {
      pcode: null,
      id_product_qualities: null,
      weight: null,
      min_weight: null,
      max_weight: null,
      asinma_min: null,
      asinma_max: null,
      asinma_sonuc: null,
      pilling_min: null,
      pilling_max: null,
      pilling_sonuc: null,
      rh_kuru: null,
      rh_yas: null,
      hav_kaybi: null,
    },
  },
  getters: {
    pending(state) {
      return state.pending
    },
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    filterData(state) {
      return state.filterData
    },
    filterParams(state) {
      return state.filterParams
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_PENDING_COUNT(state, data) {
      state.pending = data
    },
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_FILTER_DATA(state, data) {
      state.filterData = data
    },
    RESET_DATA_ITEM(state, data) {
      if (data) {
        state.dataItem = {
          id: null,
          primary_pcode: null,
          pcode: null,
          price: null,
          weight: null,
          width: null,
          content: null,
          details: null,
          id_users: null,
          id_product_groups: null,
          id_product_qualities: null,
          id_currencies: null,
          images: [],
          tests: {
            id: null,
            asinma: null,
            asinma_sonuc: null,
            pilling: null,
            pilling_sonuc: null,
            rh_kuru: null,
            rh_yas: null,
            hav_kaybi: null,
            id_products: null,
          },
        }
      }
    },
  },
  actions: {
    getPending({ commit }) {
      return axiosIns
        .post('/Admin/data/api_products/pending')
        .then(response => {
          commit('SET_PENDING_COUNT', response.data.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'products.id': id,
        },
        array: false,
        detail: true,
        isAdmin: true,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveImage({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/saveImage`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeImageData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteImage`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    getFilterData({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(`${ROOT_URL}/filterData`, data)
        .then(response => {
          commit('SET_FILTER_DATA', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    reOrderingImages({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/reOrderingImages`, data)
        .then(response => {
          // commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
