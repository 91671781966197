import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/baskets'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      company: null,
      customer_account: null,
      notes: null,
      id_customers: null,
      id_customer_accounts: null,
      id_offers: null,
      id_users: null,
      sent: null,
      items: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLineSave: {
      message: null,
      status: null,
      id: null,
    },
    search: {
      keyword: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLineSaveStatus(state) {
      return state.dataLineSave
    },
    search(state) {
      return state.search
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LINE_SAVE(state, data) {
      state.dataLineSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        company: null,
        customer_account: null,
        notes: null,
        id_customers: null,
        id_customer_accounts: null,
        id_offers: null,
        id_users: null,
        sent: null,
        items: [],
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataView({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/getBasket`, { id })
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
