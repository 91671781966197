import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
/* CORE */
import app from './routes/app'
/* ADMIN:CORE */
import configs from './routes/Admin/Core/Configs'
import users from './routes/Admin/Core/Users'
import userTypes from './routes/Admin/Core/User_types'
/* ADMIN:DEFINES */
import cities from './routes/Admin/Defines/Cities'
import countries from './routes/Admin/Defines/Countries'
import currencies from './routes/Admin/Defines/Currencies'
import customerTypes from './routes/Admin/Defines/Customer_types'
import catalogStatuses from './routes/Admin/Defines/Catalog_statuses'
import interviewStatuses from './routes/Admin/Defines/Interview_statuses'
import interviewSubjects from './routes/Admin/Defines/Interview_subjects'
import meets from './routes/Admin/Defines/Meets'
import offerStatuses from './routes/Admin/Defines/Offer_statuses'
import offerTerms from './routes/Admin/Defines/Offer_terms'
import sectors from './routes/Admin/Defines/Sectors'
import taxes from './routes/Admin/Defines/Taxes'
import units from './routes/Admin/Defines/Units'
import activities from './routes/Admin/Defines/Activities'
import relatedPersonTypes from './routes/Admin/Defines/Related_person_types'
/* ADMIN:APP */
import customers from './routes/Admin/App/Customers'
import customerPrices from './routes/Admin/App/Customer_prices'
import customerAccounts from './routes/Admin/App/Customer_accounts'
import catalogs from './routes/Admin/App/Catalogs'
import interviews from './routes/Admin/App/Interviews'
import offers from './routes/Admin/App/Offers'
import productGroups from './routes/Admin/App/Product_groups'
import productQualities from './routes/Admin/App/Product_qualities'
import products from './routes/Admin/App/Products'
import reminders from './routes/Admin/App/Reminders'
import messages from './routes/Admin/App/Messages'
import reports from './routes/Admin/App/Reports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...configs,
    ...users,
    ...userTypes,
    ...cities,
    ...countries,
    ...currencies,
    ...customerTypes,
    ...catalogStatuses,
    ...interviewStatuses,
    ...interviewSubjects,
    ...meets,
    ...offerStatuses,
    ...offerTerms,
    ...sectors,
    ...taxes,
    ...units,
    ...activities,
    ...relatedPersonTypes,
    ...customers,
    ...customerPrices,
    ...customerAccounts,
    ...catalogs,
    ...interviews,
    ...offers,
    ...productGroups,
    ...productQualities,
    ...products,
    ...reminders,
    ...messages,
    ...reports,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
