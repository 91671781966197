export default [
  {
    path: '/app/products',
    name: 'Products',
    component: () => import('@/views/Admin/Products/Index.vue'),
    meta: {
      pageTitle: 'Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/products/custom-add',
    name: 'ProductAdd',
    component: () => import('@/views/Admin/Products/CustomAdd.vue'),
    meta: {
      pageTitle: 'Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/products',
          active: false,
        },
        {
          text: 'Özel Ürün',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_CUSTOM_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/products/add/:id',
    name: 'ProductAdd',
    component: () => import('@/views/Admin/Products/Add.vue'),
    meta: {
      pageTitle: 'Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/products',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/products/edit/:id',
    name: 'ProductEdit',
    component: () => import('@/views/Admin/Products/Edit.vue'),
    meta: {
      pageTitle: 'Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/products',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/products/pending',
    name: 'ProductEdit',
    component: () => import('@/views/Admin/Products/Pending.vue'),
    meta: {
      pageTitle: 'Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/products',
          active: false,
        },
        {
          text: 'Bekleyen Ürünler',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_ADD',
      action: 'read',
    },
  },
]
